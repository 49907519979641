<template>
  <div id="app">
    <router-view>
    </router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style lang="scss">
#app {
  font-family: 'Microsoft YaHei';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.banner {
  width: calc(100%);
  height: 380px;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: justify;
  padding: 0px;

  .banner_info {
    margin-top: 180px;
    margin-left: 250px;

    .text1,
    .text2 {
      font-size: 30px;
      line-height: 60px;
      letter-spacing: 2px;
      font-weight: bold;
      color: #ffffff;
    }

    .link {
      font-size: 18px;
      line-height: 130px;
      letter-spacing: 2px;
      color: #ffffff;
      transition: all .5s;
      cursor: pointer;
      &:hover{
        text-shadow:-3px 3px 3px #ccc;
        transform: translateX(3px);
        transition: all .5s;
      }
    }
  }
}

.container {
  width: 1200px;
  height: 100%;
  margin: auto;
  padding: 50px 0;
}
.container_after{
  width: 1200px;
  height: 100%;
  margin: auto;
  padding: 0px 0 50px;
}

.main_title {
  font-size: 30px;
  color: #000;
  text-align: center;
  line-height: 70px;
  font-weight: bold;
}

.newsbody {
  padding: 50px 0;
  img {
    max-width: 1200px;
  }
}

.main_text {
  color: #333;
  font-size: 20px;
  line-height: 48px;
  letter-spacing: 2px;
}
.justify_text{
  color: #333;
  font-size: 20px;
  line-height: 48px;
  text-align: justify;
  text-indent: 50px;
  letter-spacing: 2px;
}

* {
  margin: 0;
  padding: 0;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  border-radius: 2.5px;
  background: #ccc;
}

::-webkit-scrollbar-track {
  background: #eee;
  border-radius: 2.5px;
}
a {
  text-decoration: none;
}

.router-link-active {
  text-decoration: none;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 600px;
  margin: 0;
}
</style>
