import * as axios from "axios";
import { Message } from "element-ui";
import qs from "qs";
// 创建一个 axios 实例
const instance = axios.create({
  baseURL: process.env.VUE_APP_BASEURL, // 公共的接口地址
  timeout: 60000, // 请求超时时间设置
  withCredentials: true, //跨域时使用凭证，默认带上cookies
});

// 请求拦截器，config 是发送请求的参数信息
instance.interceptors.request.use(
  (config) => {
    return config;
  },
  // 请求拦截的错误回调
  (error) => {
    return Promise.reject(error);
  }
);
// 响应拦截器，response为响应的结构组织，包括data，headers，status，statusText，config，request
instance.interceptors.response.use(
  (response) => {
    // 后台返回数据
    const data = response;
    // 这个状态码和提示信息是和后端约定的
    const { status } = data.data;
    // 根据 code 进行判断，与后端约定某些状态该返回什么数据
    switch (status) {
      // 如果没有 code，可能后端返回的数据不是json类型，直接返回响应结构
      case undefined:
        return response;
      //code === 20 代表没有错误，这里的状态 20 是与后端协商的成功状态码
      case 20:
        return data;
      case 40:
        Message.error(data.data.msg);
        return data;
      // break;
      // 其他 code，抛出错误提示信息
      default:
        return data;
    }
  },
  // 响应拦截的报错信息，协议报错
  (error) => {
    if (error && error.response) {
      Message.error(error.response.data.msg);
      switch (error.response.status) {
        case 400:
          break;
        case 401:
          // router.push({ path: "/" });
          break;
        case 403:
          break;
        case 404:
          break;
        case 408:
          break;
        case 500:
          break;
        case 501:
          break;
        case 502:
          break;
        case 503:
          break;
        case 504:
          break;
        case 505:
          break;
        default:
          break;
      }
    }
    return Promise.reject(error);
  }
);

//  设置请求头
const headers = {
  formdata: {
    "content-type": "multipart/formdata",
  },
  json: {
    "content-type": "application/json",
  },
  urlencoded: {
    "content-type": "application/x-www-form-urlencoded",
  },
};
let http = {
  get: function (url, data, config = "urlencoded") {
    return new Promise((resolve, reject) => {
      instance({
        url: url,
        method: "get",
        params: data,
        headers: headers[config],
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },

  post: function (url, data, config = "urlencoded") {
    return new Promise((resolve, reject) => {
      instance({
        url: url,
        method: "post",
        data: qs.stringify(data, { allowDots: true }),
        headers: headers[config],
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  postData: function (url, data, config = "json") {
    return new Promise((resolve, reject) => {
      instance({
        url: url,
        method: "post",
        data: data,
        headers: headers[config],
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  upload: function (url, data, config = "formdata") {
    return new Promise((resolve, reject) => {
      instance({
        url: url,
        method: "post",
        data: data,
        headers: headers[config],
      })
        .then((response) => {
          //   if (response.data.status === 20) {
          //     resolve(response.data);
          //   } else {
          //     Message.error(response.data.msg);
          //     resolve(response.data);
          //   }
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  download: function (url, data, config = "urlencoded") {
    return new Promise((resolve, reject) => {
      instance({
        url: url,
        method: "post",
        data: qs.stringify(data),
        headers: headers[config],
        responseType: "blob",
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
export default http;
