import Vue from 'vue'
import App from './App.vue'
import router from './router';
import ElementUI, {Tooltip} from 'element-ui';
import * as echarts from 'echarts'
import 'element-ui/lib/theme-chalk/index.css';
import rewriteElTooltip from "@/utils/rewriteElTooltip";

rewriteElTooltip(Tooltip)

import http from './utils/request'
Vue.prototype.$axios = http;
Vue.prototype.$echarts = echarts;

Vue.config.productionTip = false;

router.beforeEach((to, from, next) => {
  // chrome
  document.body.scrollTop = 0
  // firefox
  document.documentElement.scrollTop = 0
  // safari
  window.pageYOffset = 0
  next()
});

Vue.use(ElementUI);
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
