import Router from 'vue-router';
import Vue from 'vue';
Vue.use(Router)

const routes = [
  {
    path: '/',
    component: () => import('@/pages/public/index.vue'),
    children: [
      {
        path: "/",
        name: "home",
        component: () => import("@/pages/home/home.vue"),
        meta: {
          name: "首页",
        },
      },
      {
        path: "/product/engine",
        name: "product",
        component: () => import("@/pages/product/index.vue"),
        meta: {
          name: "核心产品",
        },
        children: [
          {
            path: "/product/engine",
            name: "engine",
            component: () => import("@/pages/product/engine.vue"),
            meta: {
              name: "自有引擎",
            },
          },
          // 自有引擎《在线体验
          {
            path: "/product/onlineExp",
            name: "onlineExp",
            component: () => import("@/pages/product/onlineExp.vue"),
            meta: {
              name: "自有引擎",
            },
          },
          {
            path: "/product/FMTool",
            name: "FMTool",
            component: () => import("@/pages/product/FMTool.vue"),
            meta: {
              name: "FM工具",
            },
          },
          {
            path: "/product/CIM",
            name: "cim",
            component: () => import("@/pages/product/cim.vue"),
            meta: {
              name: "CIM系列",
            },
          },
          {
            path: "/product/teaching",
            name: "teaching",
            component: () => import("@/pages/product/teaching.vue"),
            meta: {
              name: "教学系列",
            },
          },
          {
            path: "/product/download",
            name: "download",
            component: () => import("@/pages/product/download.vue"),
            meta: {
              name: "下载中心",
            },
          },
        ]
      },
      {
        path: "/solution",
        name: "solution",
        component: () => import("@/pages/solution/scheme.vue"),
        meta: {
          name: "解决方案",
        }
      },
      {
        path: "/teachingProduct",
        name: "teachingProduct",
        component: () => import("@/pages/teachingProduct/teachingProduct.vue"),
        meta: {
          name: "教学产品",
        },
      },
      {
        path: "/productTeachingIntegration",
        name: "productTeachingIntegration",
        component: () => import("@/pages/productTeachingIntegration/index.vue"),
        meta: {
          name: "产教融合",
        },
        children: [
            {
                path: "/productTeachingIntegration/project",
                name: "productTeachingIntegrationProject",
                component: () => import("@/pages/productTeachingIntegration/project.vue"),
                meta: {
                    name: "国家级、省部级项目"
                }
            },
            {
                path: "/productTeachingIntegration/examinationTraining",
                name: "examinationTraining",
                component: () => import("@/pages/productTeachingIntegration/examinationTraining.vue"),
                meta: {
                    name: "考试培训"
                }
            }
        ]
      },
      {
        path: "/servers",
        name: "servers",
        component: () => import("@/pages/servers/servers.vue"),
        meta: {
          name: "技术服务",
        },
      },
    //   {
    //     path: "/innovation",
    //     name: "innovation",
    //     component: () => import("@/pages/innovation/innovation.vue"),
    //     meta: {
    //       name: "创新与支持",
    //     },
    //   },
      {
        path: "/news",
        name: "news",
        component: () => import("@/pages/news/index.vue"),
        meta: {
          name: "资讯中心",
        },
        children: [
          {
            path: "/news",
            name: "list",
            component: () => import("@/pages/news/news/news.vue"),
            meta: {
              name: "资讯列表",
            },
          },
          {
            path: "/news/detail",
            name: "20220616",
            component: () => import("@/pages/news/list/detail.vue"),
            meta: {
              name: "咨讯详情",
            },
          },
        ]
      },
      {
        path: "/about",
        name: "about",
        component: () => import("@/pages/about/about.vue"),
        meta: {
          name: "关于百川",
        },
      },
    ]
  },
]
const RouterPush = Router.prototype.push;
Router.prototype.push = function push(to) {
  return RouterPush.call(this, to).catch((err) => err);
};
const router = new Router({
  mode: 'hash',
  routes: routes
})
export default router